.close {
  position: absolute;z-index: 1;
  left: calc(100% - 10px);
  top: 0;
  transform: translate(0,-100%);

  color: var(--light);
  transition: color 0.15s linear;
  @media (any-hover: hover) {
    &:hover {
      color: var(--accent);
    }
  }
  & > svg {
    stroke: currentColor;
  }
}

.overlay {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100dvw;
  height: 100dvh;

  padding: 15px 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  background: rgba(0, 0, 0, 0.40);
backdrop-filter: blur(5px);

  transition: opacity 0.15s linear, visibility 0.15s linear,
    pointer-events 0.15s linear;

  &.active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

.modal {
  max-width: 500px;

  border-radius: 3px;
  background: #0c1c19;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-transform: lowercase;
}

.label {
  display: flex;
  width: 100%;
  position: relative;

  &.error {
    > input,
    > textarea {
      border-color: var(--red);
      &::placeholder {
        color: var(--red);
      }
    }
  }
}

.error__field {
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  transform: translate(0,-50%);
  width: 16px;
  height: 16px;
}

.input, .textarea {
  font-size: 14px;
  line-height: normal;
  text-transform: none;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 12px 17px;

  color: var(--light);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid transparent;

  transition: border-color .15s linear;

  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent);
    }
  }
  &:focus {
    border-color: var(--accent);
  }
}

.textarea {
  resize: none;
  min-height: 100px;
}

@media(max-width: 767px) {
  .close {
    left: 100%;
    transform: translate(-33px, -100%);
  }
  .modal {
    padding: 20px;
  }

  .suptitle {
    margin-bottom: 10px;
  }

  .input, .textarea {
    padding: 7px 10px;
  font-size: 12px;
  }
}

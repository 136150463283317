:root {
  --ff: "Evolventa", sans-serif;
  --content-width: 1280px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light: #fff;
  --dark: #0b0b0b;
  --dark-accent: #033322;
  --accent: #03ba78;
  --gray: rgba(255, 255, 255, 0.4);
  --red: #E53434;
  --btn-border: 1px solid rgba(255, 255, 255, 0.12);
}

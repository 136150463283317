.loader {
  &__overlay {
    z-index: 11;
    position: fixed;
    height: 100dvh;
    width: 100dvw;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--dark);
  }

  position: relative;

  height: 100dvh;
  width: 627px;
  max-width: 627px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-51.5%, -52.5%);
  max-width: 627px;
  width: 100%;
  height: auto;
}

@media(max-width:1280px) {
  .container,
  .loader {
    width: 100%;
    margin: 0 auto;
  }

  .svg {
    transform: translate(-50%,-50%);
  }
}

@font-face {
  font-family: Evolventa;
  src: url('../assets/fonts/Evolventa-Regular.woff2') format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: Evolventa;
  src: url('../assets/fonts/Evolventa-Bold.woff2') format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@-webkit-keyframes animate-loader {
  0% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-loader {
  0% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

.loader {
  -webkit-animation: animate-loader 1s  0.1s infinite alternate ease-in-out both;
          animation: animate-loader 1s  0.1s infinite alternate ease-in-out both;
}

@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 168.99212646484375px;
    stroke-dasharray: 168.99212646484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.99212646484375px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 168.99212646484375px;
    stroke-dasharray: 168.99212646484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.99212646484375px;
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
          animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
}

@-webkit-keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 168.99200439453125px;
    stroke-dasharray: 168.99200439453125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.99200439453125px;
  }
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 168.99200439453125px;
    stroke-dasharray: 168.99200439453125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.99200439453125px;
  }
}

.svg-elem-2 {
  -webkit-animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.32999999999999996s both;
          animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.32999999999999996s both;
}

@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 276.116455078125px;
    stroke-dasharray: 276.116455078125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 276.116455078125px;
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 276.116455078125px;
    stroke-dasharray: 276.116455078125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 276.116455078125px;
  }
}

.svg-elem-3 {
  -webkit-animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
          animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
}

@-webkit-keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 150.73342895507812px;
    stroke-dasharray: 150.73342895507812px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 150.73342895507812px;
  }
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 150.73342895507812px;
    stroke-dasharray: 150.73342895507812px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 150.73342895507812px;
  }
}

.svg-elem-4 {
  -webkit-animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.39s both;
          animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.39s both;
}

@-webkit-keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 140.33587646484375px;
    stroke-dasharray: 140.33587646484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 140.33587646484375px;
  }
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 140.33587646484375px;
    stroke-dasharray: 140.33587646484375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 140.33587646484375px;
  }
}

.svg-elem-5 {
  -webkit-animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.42s both;
          animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.42s both;
}

@-webkit-keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 191.34400939941406px;
    stroke-dasharray: 191.34400939941406px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 191.34400939941406px;
  }
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 191.34400939941406px;
    stroke-dasharray: 191.34400939941406px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 191.34400939941406px;
  }
}

.svg-elem-6 {
  -webkit-animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.44999999999999996s both;
          animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.44999999999999996s both;
}

*,
::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
  font-family: var(--ff);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  background-color: var(--dark);
  background-image: url(../assets/img/noise.png);
  background-size: 100px 100px;
  background-repeat: repeat;
  color: var(--light);
}
body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 171%;
  color: var(--light);
}
img {
  height: auto;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
a {
  text-decoration: none;
  -webkit-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
a:hover {
  color: var(--accent);
}
#root {
  height: 100%;
}

.site-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  z-index: 2;
}
.main:after {
  z-index: -1;
  content: "";
  position: absolute;
  left: calc(50% - 140px);
  top: -285px;
  width: 1792px;
  height: 1470px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: url(../assets/img/hero-bg.png);
  filter: blur(7.5px);
  -webkit-filter: blur(7.5px);
  -moz-filter: blur(7.5px);
  -ms-filter: blur(0.5px);
  pointer-events: none;
}
section {
  margin-bottom: 140px;
  position: relative;
  z-index: 2;
}
.footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.is-hidden {
  display: none !important;
}
a,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
ul {
  margin: 0;
  padding: 0;
  color: var(--light);
}
button {
  border: none;
  background: 0 0;
  cursor: pointer;
  outline: 0;
}
ul {
  list-style: none;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--light);
  outline: 0;
}
input::-webkit-search-cancel-button,
input::-webkit-search-decoration,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}
.overflow-h {
  overflow: hidden;
}
.dis-scroll {
  overflow: hidden;
  height: 100vh;
  transition: height 0.2s linear, min-height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.dis-scroll .header {
  padding-right: 18px;
}
.dis-scroll .burger__overlay > .burger {
  padding-right: 18px;
}
.btn {
  position: relative;
  z-index: 1;
  color: var(--light);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  border: var(--btn-border);
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 57px;
  -webkit-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.btn__angle {
  z-index: 1;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  stroke: var(--light);
  width: 7px;
  height: 7px;
}
.btn__angle:nth-child(1) {
  left: -1px;
  top: -1px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.btn__angle:nth-child(2) {
  left: -1px;
  bottom: -1px;
}
.btn__angle:nth-child(3) {
  right: -1px;
  top: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.btn__angle:nth-child(4) {
  right: -1px;
  bottom: -1px;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.btn:before {
  z-index: -1;
  content: "";
  position: absolute;
  background-color: var(--light);
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.btn-bg {
  color: var(--dark);
}
.btn-bg:before {
  opacity: 1;
}
.section-head {
  margin-bottom: 90px;
  position: relative;
}
.section-suptitle {
  position: absolute;
  margin-top: 20px;
  text-transform: capitalize;
}
.section-title {
  position: relative;
  left: 255px;
  max-width: 850px;
}
.suptitle {
  color: var(--light);
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.title-section {
  color: var(--light);
  font-size: 52px;
  font-weight: 700;
  line-height: 98.508%;
  text-transform: lowercase;
}
.title-hidden {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
}
.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
@media (any-hover: hover) {
  .btn:hover {
    color: var(--light);
  }
  .btn:hover:before {
    opacity: 0.2;
  }
  .logo:hover {
    opacity: 0.7;
  }
}
.overlay {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.2s linear, visibility 0.2s linear,
    pointer-events 0.2s linear;
  transition: opacity 0.2s linear, visibility 0.2s linear,
    pointer-events 0.2s linear;
}
.overlay.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.first-screen {
  min-height: 100vh;
  margin-bottom: 30px;
  transition: height 0.2s linear, min-height 0.2s linear;
}
.first-screen__inner {
  min-height: 100vh;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  transition: height 0.2s linear, min-height 0.2s linear;
}
.first-screen__info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 40px;
  -webkit-transform: translate(0, -200px);
  -ms-transform: translate(0, -200px);
  transform: translate(0, -200px);
}
.br-mob {
  display: none;
}
.indev {
  position: relative;
  cursor: default;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.indev-link:hover {
  color: rgba(255, 255, 255, 0.3);
}
.indev:hover span {
  opacity: 1;
}
.indev span {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: calc(100% + 15px);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 3px;
  color: var(--light);
  border: 1px solid #5b5b5b;
  background: rgba(8, 27, 20, 0.71);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-transform: none;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.swiper-slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.swiper-pagination {
  margin: 30px auto 0 !important;
  position: static !important;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  left: unset !important;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.swiper-pagination-bullet {
  height: 6px !important;
  width: 6px !important;
  margin: 0;
  background: var(--light) !important;
  opacity: 0.15 !important;
  -webkit-transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
  transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
}
.swiper-pagination-bullet-active {
  background: var(--light) !important;
  opacity: 1 !important;
  width: 8px !important;
  height: 8px !important;
}
.video-js {
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}
.video-js.vjs-has-started:after,
.video-js.vjs-has-started:before {
  opacity: 0;
  pointer-events: none;
}
.video-js:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.video-js:after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.video-js .vjs-control-bar {
  background: rgba(0, 0, 0, 0.6);
}
.video-js .vjs-big-play-button {
  z-index: 2;
  width: 222px !important;
  height: 24px !important;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0 !important;
  border: unset !important;
  background: unset !important;
  -webkit-transition: opacity 0.2s linear !important;
  transition: opacity 0.2s linear !important;
}
.video-js .vjs-big-play-button > span {
  display: none;
}
.video-js .vjs-big-play-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  background-size: contain;
  background-image: url(../assets/img/logo.svg);
  background-repeat: no-repeat;
}

.vjs-poster {
  background-size: cover !important;
}

.vjs-poster img {
  object-fit: cover !important;
}

.header {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 34px 0;
  gap: 10px;
}
.header__burger {
  --burger-width: 59px;
  --burger-height: 40px;
  --burger-line-height: 1px;
  z-index: 1;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0) scale(1);
  -ms-transform: translate(-50%, 0) scale(1);
  transform: translate(-50%, 0) scale(1);
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--light);
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: width 0.2s linear, -webkit-transform 0.2s linear;
  transition: transform 0.2s linear, width 0.2s linear,
    -webkit-transform 0.2s linear;
}
.header__burger .btn__angle {
  display: none;
}
@media (any-hover: hover) {
  .video-js:hover .vjs-big-play-button {
    opacity: 0.5;
  }
  .header__burger:hover {
    -webkit-transform: translate(-50%, 0) scale(1.1);
    -ms-transform: translate(-50%, 0) scale(1.1);
    transform: translate(-50%, 0) scale(1.1);
  }
  .header__lang-btn:hover {
    color: var(--accent);
  }
}
.header__burger::after,
.header__burger::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  -webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}
.header__burger::before {
  top: calc(50% - 4px - var(--burger-line-height));
}
.header__burger::after {
  top: calc(50% + 4px + var(--burger-line-height));
}
.header__burger.active {
  width: 44px;
}
.header__burger.active::before {
  top: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}
.header__burger.active::after {
  top: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}
.header__btn {
  font-size: 12px;
  padding: 16px 30px;
  min-width: 182.95px;
}
.header__btn .btn__angle {
  width: 5.5px;
  height: 5.5px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.header__lang {
  position: absolute;
  right: 0;
}
.header__lang.active .header__lang-btn {
  color: var(--accent);
}
.header__lang.active .header__lang-btn-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.header__lang.active .header__lang-list,
.header__lang.active .header__lang-overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.header__lang-overlay {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.2s linear, visibility 0.2s linear,
    pointer-events 0.2s linear;
  transition: opacity 0.2s linear, visibility 0.2s linear,
    pointer-events 0.2s linear;
}
.header__lang-btn {
  color: var(--light);
  text-transform: uppercase;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  z-index: 2;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.header__lang-btn-arrow {
  fill: currentColor;
  width: 9px;
  height: 7px;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}
.header__lang-list {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: calc(100% + 11px);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: rgba(23, 23, 23, 0.5);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.header__lang-list-btn {
  background: rgba(23, 23, 23, 0.5);
  -webkit-transition: background 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
  width: 65px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
}
.header__lang-list-btn.active {
  color: var(--accent);
  background: rgba(23, 23, 23, 0.5);
  pointer-events: none;
}
@media (any-hover: hover) {
  .header__lang-list-btn:hover {
    background: #262626;
  }
}
.burger {
  z-index: 1;
  background: rgba(2, 10, 7, 0.76);
  -webkit-backdrop-filter: blur(41px);
  backdrop-filter: blur(41px);
  padding: 195px 0 70px;
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.burger__overlay.active .burger {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.burger__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 130px;
}
.burger__list-link {
  font-size: 49.352px;
  line-height: normal;
}
.burger__list-link.active {
  color: var(--accent);
  pointer-events: none;
}
.burger__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.burger__contacts {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 282px);
  gap: 12px 130px;
  white-space: nowrap;
  font-size: 18px;
  line-height: normal;
  text-transform: none;
}
.burger__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  font-size: 18px;
  line-height: 201.5%;
}
.burger__btn {
  display: none;
}
.separator {
  margin-bottom: 140px;
  position: relative;
  z-index: 2;
}
.separator__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.separator__img {
  width: 25px;
  height: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.hero__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  pointer-events: none;
}
.hero__quote {
  max-width: 500px;
  position: absolute;
  left: 0;
  bottom: 7%;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.48px;
  z-index: 3;
}
.hero__btn {
  position: absolute;
  right: 0;
  bottom: 7%;
  z-index: 3;
}
.hero__btn .btn__angle {
  width: 7px;
  height: 7px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.advantages {
  &__inner {
    position: relative;
    &:before {
      width: 550px;
      height: 487px;
      flex-shrink: 0;
      content: "";
      z-index: 1;
      position: absolute;
      pointer-events: none;
      left: -194px;
      top: 28px;
      border-radius: 864px;
      background: var(--dark-accent);
      filter: blur(200px);
    }
  }

  &__title {
    margin-bottom: 100px;
    max-width: 435px;
    position: relative;
    z-index: 2;
  }

  &__suptitle {
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
  }

  &__card {
    &-outer {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 10px;
      position: relative;
      z-index: 2;
    }

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 4;
    }
    &:nth-child(3) {
      grid-area: 1 / 5 / 2 / 6;
    }
    &:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
    }
    &:nth-child(5) {
      grid-area: 2 / 4 / 3 / 5;
    }

    text-align: center;
    max-width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 170%;
      max-width: 210px;
    }

    &-img {
      width: 95px;
      height: 95px;
      &-outer {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        width: 165px;
        height: 165px;
        margin-bottom: 36px;
      }
    }
  }
}

.about__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.about__inner:before {
  z-index: 1;
  content: "";
  position: absolute;
  border-radius: 550px;
  background: var(--dark-accent);
  filter: blur(180px);
  -webkit-filter: blur(180px);
  -moz-filter: blur(180px);
  -ms-filter: blur(180px);
  width: 550px;
  height: 487px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  left: -197px;
  top: 28px;
}
.about__head {
  max-width: 297px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 2;
  position: relative;
}
.about__btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  max-width: 245px;
  width: 100%;
}
.about__info {
  position: relative;
  z-index: 2;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 152px;
}
.about__info-text {
  max-width: 317px;
  text-transform: none;
}
.about__info-text:nth-child(1) {
  padding-top: 38px;

  -ms-grid-row-span: 1;

  -ms-grid-column-span: 1;
  grid-area: 1/1/2/2;
}
.about__info-text:nth-child(2) {
  margin-top: auto;

  -ms-grid-row-span: 1;

  -ms-grid-column-span: 1;
  grid-area: 2/1/3/2;
}
.about__info-text:nth-child(2) > span {
  color: var(--accent);
  font-weight: 700;
}
.about__img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.about__img-outer {
  -ms-grid-row-span: 2;

  -ms-grid-column-span: 1;
  grid-area: 1/2/3/3;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  width: 363px;
  height: 478px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.works {
  &__inner {
    position: relative;
    z-index: 1;
  }
  &__inner:before {
    content: "";
    z-index: 1;
    position: absolute;
    pointer-events: none;
    width: 864px;
    height: 765px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    left: calc(50% - 138px);
    top: 315px;
    border-radius: 864px;
    background: var(--dark-accent);
    filter: blur(200px);
    -webkit-filter: blur(200px);
    -moz-filter: blur(200px);
    -ms-filter: blur(200px);
  }
  &__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 1;

    &-outer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      margin-bottom: 90px;
    }
  }
  &__block > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: auto;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 12px;
  }

  &__img {
    width: auto;
    height: 323px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 5px;
  }
  &__img-long {
    width: 403px;
  }
  &__text {
    color: rgba(255, 255, 255, 0.47);
    width: 403px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: auto;
    position: absolute;
    top: 415px;
    z-index: 1;
  }
  &__text > span {
    font-weight: 700;
  }
  &__btn {
    margin-top: auto;
    width: 100%;
    z-index: 1;
    max-width: 400px;
    &-outer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      z-index: 1;
      max-width: 400px;
    }
  }
}
.team__inner {
  position: relative;
  z-index: 2;
}
.team__inner:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 624px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 3323px;
  height: 1270px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  pointer-events: none;
  background: #0b0b0b;
  filter: blur(180px);
  -webkit-filter: blur(180px);
  -moz-filter: blur(180px);
  -ms-filter: blur(180px);
}

.team__head {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}
.team__suptitle {
  position: static;
  margin-top: 0;
}
.team__title {
  max-width: 850px;
  position: static;
  left: unset;
}
.team__slider {
  overflow: visible;
  margin-bottom: 80px;
}
.team__slide {
  max-width: 275px;
}
.team__slide-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 275px;
}
.team__slide-img {
  width: 100%;
  height: 344px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}
.team__slide-info {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 5px;
  text-transform: none;
}
.team__slide-name {
  font-size: 18px;
}
.team__slide-position {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}
.team__btn {
  padding: 22px 73px;
  z-index: 2;
}
.team__indev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 73px;
}
.services__block {
  position: relative;
  padding: 32px 20px 48px 40px;
}
.services__block-outer {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.services__block-angle {
  z-index: 1;
  position: absolute;
  stroke: var(--accent);
  width: 8px;
  height: 8px;
}
.services__block-angle:nth-child(1) {
  left: 0;
  top: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.services__block-angle:nth-child(2) {
  left: 0;
  bottom: 0;
}
.services__block-angle:nth-child(3) {
  right: 0;
  top: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.services__block-angle:nth-child(4) {
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.services__block-title {
  color: var(--gray);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 42px;
}
.services__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 9px;
}
.services__list-item {
  line-height: 201.512%;
}
.learnMore__inner {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.learnMore__inner:before {
  z-index: 1;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 917px;
  height: 487px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 917px;
  background: var(--dark-accent);
  filter: blur(200px);
  -webkit-filter: blur(200px);
  -moz-filter: blur(200px);
  -ms-filter: blur(200px);
}
.learnMore__title {
  font-weight: 400;
  font-size: 16px;
  margin-right: 149px;
  text-transform: none;
  position: relative;
  z-index: 2;
}
.learnMore__link {
  position: relative;
  font-size: 52px;
  font-weight: 700;
  line-height: 119.508%;
}
.learnMore__link-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px 122px;
  position: relative;
  z-index: 2;
}
.learnMore__link:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.learnMore__link:nth-child(1):after {
  background: #4098ff;
}
.learnMore__link:nth-child(2):before {
  content: "instagram";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  font-size: 52px;
  font-weight: 700;
  line-height: 119.508%;
  background: linear-gradient(134deg, #ff4040 0, #ab40ff 100%);

  -webkit-text-fill-color: transparent;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.learnMore__link:nth-child(2):after {
  background: linear-gradient(134deg, #ff4040 0, #ab40ff 100%);
}
.learnMore__link:nth-child(3):after {
  background: #0038ff;
}
.news__head {
  margin-bottom: 40px;
}
.news__slider {
  overflow: visible;
}
.news__slide {
  max-width: 297px;
}
.news__slide-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  max-width: 297px;
}
.news__slide-img {
  width: 100%;
  height: 427px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 5px;
}
.news__slide-text {
  font-size: 18px;
  line-height: 201.5%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.contacts__inner {
  position: relative;
  z-index: 1;
}
.contacts__inner:before {
  z-index: 1;
  content: "";
  position: absolute;
  left: calc(50% - 30px);
  top: -80px;
  width: 917px;
  height: 487px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 917px;
  background: var(--dark-accent);
  filter: blur(180px);
  -webkit-filter: blur(180px);
  -moz-filter: blur(180px);
  -ms-filter: blur(180px);
}
.contacts__head {
  margin-bottom: 32px;
  position: relative;
  z-index: 2;
  display: flex;
  gap: 184px;
}
.contacts__suptitle {
  margin-top: 20px;
}
.contacts__title {
  font-size: 86.295px;
  font-weight: 700;
  line-height: 119.508%;
}
.contacts__title > span {
  color: var(--accent);
}
.contacts__info {
  position: relative;
  z-index: 2;
  left: 255px;
}
.contacts__list {
  max-width: 741px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 370px);
  grid-gap: 12px 0;
  margin-bottom: 32px;
}
.contacts__list-item {
  font-size: 18px;
  white-space: nowrap;
  text-transform: none;
}
.contacts__btn {
  padding: 22px 38px;
  left: 369px;
}
.casesHero__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.casesHero__title {
  max-width: 684px;
}
.casesHero__text {
  max-width: 452px;
}
.casesBest__slider-pagination {
  display: none;
}
.casesBest__block {
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.casesBest__block_outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (any-hover: hover) {
  .learnMore__link:nth-child(1):hover {
    color: #4098ff;
  }
  .learnMore__link:nth-child(2):hover {
    color: #ff40b9;
  }
  .learnMore__link:nth-child(3):hover {
    color: #0038ff;
  }
  .casesBest__block:hover .casesBest__block-img,
  .casesBest__block:hover .casesBest__block-before {
    opacity: 1;
  }
  .casesAll__sort-btn:hover {
    color: var(--accent);
  }
}
.casesBest__block-before {
  background: linear-gradient(132deg, #dd5031 0, #eb8322 100%);
  z-index: -1;
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  height: 100%;
  width: 3000px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.casesBest__block-title {
  font-size: 46px;
  line-height: 124.508%;
  text-transform: lowercase;
}
.casesBest__block-img {
  border-radius: 10px;
  position: absolute;
  left: 319px;
  top: 50%;
  height: 263px;
  width: 389px;
  border-radius: 5px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.casesBest__block-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 22px;
  max-width: 452px;
}
.casesBest__block-btn {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}
.casesBest__block-btn-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.casesAll__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 100px;
}
.casesAll__sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 930px;
  gap: 38.5px 86px;
}
.casesAll__sort-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  position: relative;
}
.casesAll__sort-btn > span {
  position: absolute;
  left: 101%;
  bottom: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--accent);
}
.casesAll__sort-btn.active {
  color: var(--accent);
  pointer-events: none;
}
.casesAll__case {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.casesAll__case-outer {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 120px 59px;
}
.casesAll__case-img {
  height: 420px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.casesAll__case-title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: none;
}
.casesAll__case-text {
  text-transform: none;
  line-height: 160%;
}
.aboutHero__title-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
  max-width: 900px;
}
.aboutHero__text {
  max-width: 225px;
}
.aboutText__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 40px;
}
.aboutText__text {
  max-width: 416px;
  position: sticky;
  margin-top: 18px;
}
.aboutText__title {
  max-width: 650px;
  font-size: 42px;
  text-transform: lowercase;
  line-height: 160%;
}
.aboutVideo__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 60px;
}
.aboutVideo__video {
  width: 100%;
  height: 420px;
}
.aboutReviews {
  z-index: 1;
}
.aboutReviews__inner {
  position: relative;
  z-index: 2;
}
.aboutReviews__inner:before {
  z-index: -1;
  content: "";
  position: absolute;
  border-radius: 550px;
  background: var(--dark-accent);
  filter: blur(220px);
  -webkit-filter: blur(220px);
  -moz-filter: blur(220px);
  -ms-filter: blur(220px);
  width: 917px;
  height: 487px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0.7;
  left: calc(50% - 63px);
  top: -46px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.aboutReviews__head {
  margin-bottom: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 240px;
}
.aboutReviews__slider {
  overflow: visible;
}
.aboutReviews__slide {
  max-width: 502px;
  position: relative;
  padding: 42px;
  text-transform: none;
}
.aboutReviews__slide-angle {
  z-index: 1;
  position: absolute;
  stroke: var(--accent);
  width: 8px;
  height: 8px;
}
.aboutReviews__slide-angle:nth-child(1) {
  left: 0;
  top: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.aboutReviews__slide-angle:nth-child(2) {
  left: 0;
  bottom: 0;
}
.aboutReviews__slide-angle:nth-child(3) {
  right: 0;
  top: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.aboutReviews__slide-angle:nth-child(4) {
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.aboutReviews__slide-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 40px;
}
.aboutReviews__slide-text {
  overflow: hidden;
  line-height: 160%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.aboutReviews__slide-user-name {
  font-size: 16px;
}
.contactsHero {
  margin-bottom: 300px;
}
.contactsHero__inner {
  top: 20vh;
  position: relative;
  z-index: 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.contactsHero__head {
  margin-bottom: 135px;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.contactsHero__suptitle {
  margin-bottom: 0;
  text-transform: lowercase;
}
.contactsHero__title {
  font-size: 86px;
  font-weight: 700;
  line-height: 119.508%;
}
.contactsHero__title-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 17px;
  -webkit-transform: translate(0, -15px);
  -ms-transform: translate(0, -15px);
  transform: translate(0, -15px);
}
.contactsHero__title > span {
  color: var(--accent);
}
.contactsHero__subtitle {
  max-width: 323px;
  line-height: 155.008%;
  text-transform: lowercase;
}
.contactsHero__feedback {
  max-width: 200px;
  width: 100%;
  padding: 20px 35px;
  font-size: 11.541px;
  color: var(--light);
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  transform: translate(0, 15px);
}
.contactsHero__feedback > .btn__angle {
  stroke: var(--accent);
}
.contactsHero__feedback:before {
  background-color: var(--accent) !important;
}
.contactsHero__info {
  position: relative;
  z-index: 2;
  left: 255px;
}
.contactsHero__list {
  max-width: 741px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 370px);
  grid-gap: 12px 0;
  margin-bottom: 32px;
}
.contactsHero__list-item {
  font-size: 18px;
  white-space: nowrap;
  text-transform: none;
}
.contactsHero__btn {
  padding: 22px 38px;
  left: 369px;
}
.contactsLearnMore__inner:before {
  width: 917px;
  height: 487px;
  left: calc(50% + 200px);
  top: -81px;
  -webkit-transform: unset;
  -ms-transform: unset;
  transform: unset;
}
.footer {
  position: relative;
  z-index: 2;
  padding-bottom: 33px;
}
.footer__high {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.footer__logo {
  width: 427px;
}
.footer__logo-outer {
  position: relative;
}
.footer__city {
  position: absolute;
  top: 84px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
}
.footer__list-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 124px;
}
.footer__list-title {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.footer__list-item {
  line-height: 201.512%;
  text-transform: lowercase;
}
.footer__btn {
  color: var(--light);
  padding-left: 33px;
  padding-right: 33px;
  font-size: 12px;
}
.footer__btn:before {
  background-color: var(--accent) !important;
}
.footer__low {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
}
.footer__policy {
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 201.512%;
}

.modal__title,
.modal__subtitle {
  text-align: center;
}

.modal__title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 14px;
  line-height: 98.508%;
}

.modal__subtitle {
  font-size: 16px;
  max-width: 317px;
  margin-bottom: 50px;
}

.modal__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal__btn {
  margin-top: 10px;
  width: 100%;
}
@media (max-width: 1200px) {
  .dis-scroll .burger__overlay > .burger,
  .dis-scroll .header {
    padding-right: 0;
  }
  .btn-bg {
    color: var(--light);
  }
  .btn-bg:before {
    background-color: rgba(255, 255, 255, 0.13);
  }
  .burger {
    padding-top: 120px;
  }
  .burger__list {
    margin-bottom: 50px;
  }
  .burger__list-link.indev-link span {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0, -50%);
    height: min-content;
  }
  html {
    background-image: url(../assets/img/noise-mob.png);
  }
  .header__burger {
    position: relative;
    left: unset !important;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border: 0.809px solid rgba(255, 255, 255, 0.12);
    width: 55px;
    height: 55px;
  }
  .header__burger:after,
  .header__burger:before {
    width: 21px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .header__burger .btn__angle {
    display: block;
  }
}
@media (max-width: 1200px) and (any-hover: hover) {
  .header__burger:hover {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@media (max-width: 1200px) {
  .header__burger.active {
    width: 55px;
  }
  .header__burger.active:before {
    -webkit-transform: translate(-50%, 0) rotate(45deg);
    -ms-transform: translate(-50%, 0) rotate(45deg);
    transform: translate(-50%, 0) rotate(45deg);
  }
  .header__burger.active:after {
    -webkit-transform: translate(-50%, 0) rotate(-45deg);
    -ms-transform: translate(-50%, 0) rotate(-45deg);
    transform: translate(-50%, 0) rotate(-45deg);
  }
  .header__btn {
    display: none;
  }
  .header__lang {
    right: 80px;
  }
  .hero__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .hero__logo {
    width: 100%;
  }
  .hero__logo-angle {
    display: none;
  }
  .hero__quote {
    top: 50%;
    bottom: unset;
    left: 50%;
    transform: translate(-50%, 40px);
  }
  .hero__btn {
    right: unset;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    max-width: 360px;
    width: 100%;
  }

  .advantages {
    &__inner {
      &:before {
        left: -233px;
        top: 0;
      }
    }
    &__suptitle {
      margin-bottom: 3px;
    }
    &__title {
      max-width: 160px;
      margin-bottom: 60px;
    }
    &__card {
      &-outer {
        gap: 60px;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(1, 1fr);
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        grid-area: unset;
      }

      max-width: 100%;

      &-img {
        &-outer {
          margin-bottom: 30px;
        }
      }
    }
  }

  .about__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about__inner:before {
    top: 217px;
    left: -135px;
    filter: blur(180px);
    -webkit-filter: blur(180px);
    -moz-filter: blur(180px);
    -ms-filter: blur(180px);
  }
  .about__head {
    max-width: 100%;
    margin-bottom: 38px;
  }
  .about__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 42px;
    margin-bottom: 42px;
  }
  .about__info-text {
    max-width: unset;
  }
  .about__info-text:nth-child(1) {
    padding-top: 0;
    grid-area: unset;
  }
  .about__info-text:nth-child(2) {
    grid-area: unset;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .about__img-outer {
    grid-area: unset;
    width: 100%;
  }
  .about__img-outer:after {
    display: none;
  }
  .about__btn {
    position: relative;
    left: unset;
    bottom: unset;
    max-width: 100%;
  }
  .about__btn:before {
    background: rgba(255, 255, 255, 0.13);
    opacity: 1;
  }

  .works {
    &__block {
      width: 100%;
      height: auto;

      &-outer {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &__img {
      width: 100%;
    }
    &__text {
      width: 100%;
      left: calc(100% + 37px);
      top: 0;
    }
  }

  .team__inner:before {
    top: 333px;
  }

  .team__suptitle {
    position: static;
    margin-top: unset;
  }
  .team__slider {
    margin-bottom: 50px;
  }
  .team__slide,
  .team__slide-content {
    max-width: 220px;
  }
  .team__slide-img {
    height: 275px;
  }
  .team__slide-info {
    padding-left: 12px;
  }
  .team__slide-name {
    font-size: 16px;
  }
  .team__slide-position {
    font-size: 12px;
  }

  .services__title {
    left: 130px;
  }

  .services__block-outer {
    grid-template-columns: repeat(2, 1fr);
  }
  .learnMore__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }
  .learnMore__title {
    white-space: nowrap;
  }
  .learnMore__link-outer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 46px;
  }
  .contacts__title {
    font-size: 76.295px;
  }
  .contacts__btn,
  .contacts__info {
    left: 0;
  }
  .first-screen__info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .casesHero__text {
    max-width: 635px;
  }
  .casesBest__slider-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .casesBest__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 23px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    padding: 18px 15px 20px;
    border-radius: 3px;
  }
  .casesBest__block-before {
    left: 0;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    width: 100%;
    opacity: 1;
  }
  .casesBest__block-img {
    width: 100%;
    max-width: 300px;
    height: 175px;
    position: static;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    opacity: 1;
  }
  .casesBest__block-btn {
    max-width: 100%;
  }
  .aboutHero__suptitle {
    display: none;
  }
  .aboutText__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
  }
  .aboutVideo__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 24px;
  }
  .contactsHero__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .contactsHero__info {
    left: 0;
  }
  .footer__high {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .footer__city {
    position: static;
    text-align: center;
  }
  .footer__logo {
    max-width: 495px;
    width: 100%;
    margin-bottom: 40px;
  }
  .footer__logo-outer {
    margin-bottom: 63px;
  }
  .footer__low {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 24px;
  }
}
@media (max-width: 767px) {
  .title-section {
    font-size: 30px;
  }
  .suptitle {
    font-size: 12px;
  }
  .video-js {
    height: 197px;
  }
  .video-js .vjs-big-play-button {
    width: 103.697px !important;
    height: 11.166px !important;
  }
  .br-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  body {
    font-size: 14px;
  }
  .main:after {
    width: 240vw;
    height: 105vh;
    left: unset;
    right: -211px;
    top: -3vh;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    background-position: center center;
    background-size: cover;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -ms-filter: blur(3px);
  }
  section {
    margin-bottom: 60px;
  }
  .section-head {
    margin-bottom: 40px;
  }
  .section-title {
    left: unset;
  }
  .section-suptitle {
    position: static;
    margin-top: 0;
  }
  .burger {
    padding: 170px 0 28px;
  }
  .burger__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
  }
  .burger__list-link {
    font-size: 26px;
  }
  .burger__list-link:before {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    left: calc(100% + 10px);
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .burger__contacts {
    display: none;
  }
  .burger__social {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
  }
  .burger__btn {
    margin-top: 30px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .hero {
    margin-bottom: 60px;
  }
  .hero__quote {
    max-width: 280px;
    width: 100%;
  }
  .hero__btn {
    max-width: 270px;
  }
  .separator {
    margin-bottom: 60px;
  }
  .about__inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .about__head {
    max-width: 200px;
  }
  .about__img-outer {
    height: 215px;
    width: 100%;
  }
  .works {
    &__img {
      height: 284px;
    }
    &__text {
      position: static;
    }
    &__suptitle {
      margin-bottom: 3px;
      font-size: 14px;
    }
    &__title {
      max-width: 160px;
    }

    &__card {
      &-outer {
        margin-bottom: 40px;
      }
    }
  }
  .team {
    margin-bottom: 110px;
  }
  .team__inner:before {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: -600px;
    top: 292px;
    background: #0b0b0b;
    filter: blur(75.5px);
    -webkit-filter: blur(75.5px);
    -moz-filter: blur(75.5px);
    -ms-filter: blur(75.5px);
  }
  .team__btn,
  .team__indev {
    width: 100%;
    position: relative;
    top: unset;
    right: unset;
  }
  .services__title br {
    display: none;
  }
  .services__block-outer {
    grid-template-columns: repeat(1, 1fr);
  }
  .services__block-title {
    font-size: 18px;
  }
  .services__list-item {
    font-size: 16px;
  }
  .learnMore__inner:before {
    left: 15px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 453px;
    height: 487px;
    border-radius: 487px;
    filter: blur(180px);
    -webkit-filter: blur(180px);
    -moz-filter: blur(180px);
    -ms-filter: blur(180px);
  }
  .learnMore__title {
    margin-bottom: 33px;
  }
  .learnMore__link {
    font-size: 38.316px;
  }
  .learnMore__link:nth-child(2):before {
    font-size: 38px;
  }
  .news__suptitle {
    margin-bottom: 0;
  }
  .news__slide {
    max-width: 240px;
  }
  .news__slide-content {
    max-width: 240px;
    gap: 16px;
  }
  .news__slide-img {
    height: 347px;
  }
  .news__slide-text {
    font-size: 14px;
  }
  .contacts__inner:before {
    width: 453px;
    height: 487px;
    left: 55px;
    top: -53px;
    border-radius: 487px;
    filter: blur(180px);
    -webkit-filter: blur(180px);
    -moz-filter: blur(180px);
    -ms-filter: blur(180px);
  }
  .contacts__head {
    flex-direction: column;
    gap: 0;
  }
  .contacts__suptitle {
    margin-bottom: 21px;
  }
  .contacts__title {
    font-size: 32px;
  }
  .contacts__list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }
  .contacts__list-item:nth-child(2) {
    margin-bottom: 23px;
  }
  .contacts__btn {
    width: 100%;
  }
  .first-screen__info {
    gap: 16px;
    -webkit-transform: translate(0, -15vh);
    -ms-transform: translate(0, -15vh);
    transform: translate(0, -15vh);
  }
  .casesBest__slider-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .casesBest__block-title {
    font-size: 23px;
  }
  .casesBest__block-text {
    font-size: 12px;
    line-height: 155.008%;
  }
  .casesAll__inner {
    gap: 60px;
  }
  .casesAll__sort {
    gap: 26px 48px;
  }
  .casesAll__sort-btn {
    font-size: 14px;
  }
  .casesAll__case-outer {
    gap: 40px;
  }
  .casesAll__case-img {
    height: 284px;
  }
  .aboutText__text {
    margin-top: 0;
    max-width: 100%;
  }
  .aboutText__title {
    font-size: 22px;
    line-height: 140%;
  }
  .aboutReviews__inner:before {
    top: 100px;
  }
  .aboutReviews__slide {
    padding: 20px 30px;
    max-width: 264px;
  }
  .aboutReviews__slide-content {
    gap: 0;
  }
  .aboutReviews__slide-logo {
    width: 127px;
    margin-bottom: 20px;
  }
  .aboutReviews__slide-text {
    font-size: 12px;
    margin-bottom: 34px;
  }
  .aboutReviews__slide-user-name,
  .aboutReviews__slide-user-position {
    font-size: 12px;
  }
  .contactsHero {
    margin-bottom: 0;
  }
  .contactsHero__inner {
    top: 0;
    padding: 200px 0 60px;
  }
  .contactsHero__head {
    margin-bottom: 48px;
  }
  .contactsHero__suptitle {
    font-size: 12px;
    margin-bottom: 32px;
  }
  .contactsHero__title {
    font-size: 32px;
  }
  .contactsHero__title-outer {
    gap: 16px;
  }
  .contactsHero__subtitle {
    line-height: normal;
  }
  .contactsHero__feedback {
    max-width: 100%;
  }
  .contactsHero__list {
    grid-template-columns: repeat(1, 1fr);
  }
  .contactsHero__list-item:nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .contactsHero__list-item:nth-child(3) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-bottom: 16px;
  }
  .contactsHero__list-item:nth-child(2) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .contactsHero__list-item:nth-child(4) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .contactsHero__btn {
    left: 0;
    width: 100%;
  }
  .contactsLearnMore {
    z-index: 1;
  }
  .footer {
    margin-top: 60px;
  }
  .footer__high {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }
  .footer__logo {
    max-width: 240px;
    margin-bottom: 12px;
  }
  .footer__logo-outer {
    margin-bottom: 0;
  }
  .footer__city {
    position: static;
    font-size: 14px;
    margin-bottom: 63px;
    text-align: left;
  }
  .footer__list-outer {
    gap: 64px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .footer__btn {
    margin-top: 0;
    width: 100%;
  }
  .footer__policy {
    line-height: normal;
  }

  .modal__title {
    font-size: 24px;
  }

  .modal__subtitle {
    font-size: 12px;
    line-height: normal;
    margin-bottom: 20px;
  }

  .modal__btn {
    padding: 14px 57px;
  }
}
